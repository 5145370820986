import { render, staticRenderFns } from "./login-menu.vue?vue&type=template&id=0f4e7dc4"
import script from "./login-menu.vue?vue&type=script&lang=js"
export * from "./login-menu.vue?vue&type=script&lang=js"
import style0 from "./login-menu.vue?vue&type=style&index=0&id=0f4e7dc4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzDottedLoader: require('/opt/razlet.kg/node_modules/@razlet/ui/src/components/dotted-loader/index.vue').default,RzIconSwap: require('/opt/razlet.kg/node_modules/@razlet/ui/src/icons/icon-swap/index.vue').default,RzDropdown: require('/opt/razlet.kg/node_modules/@razlet/ui/src/components/dropdown/index.vue').default})
