import { render, staticRenderFns } from "./birthday-message.vue?vue&type=template&id=44f66c8d"
import script from "./birthday-message.vue?vue&type=script&lang=js"
export * from "./birthday-message.vue?vue&type=script&lang=js"
import style0 from "./birthday-message.vue?vue&type=style&index=0&id=44f66c8d&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzIconCross: require('/opt/razlet.kg/node_modules/@razlet/ui/src/icons/icon-cross/index.vue').default,RzContainer: require('/opt/razlet.kg/node_modules/@razlet/ui/src/components/container/index.vue').default})
