import { render, staticRenderFns } from "./main-header.vue?vue&type=template&id=15b97ab1&scoped=true"
import script from "./main-header.vue?vue&type=script&lang=js"
export * from "./main-header.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15b97ab1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DesktopHeader: require('/opt/razlet.kg/src/components/desktop-header.vue').default,MobileHeader: require('/opt/razlet.kg/src/components/mobile-header.vue').default,RzDeviceView: require('/opt/razlet.kg/node_modules/@razlet/ui/src/components/device-view/index.vue').default})
